import {
  Query,
  setContent,
  setError,
  setIsLoading,
  setStatusCode,
} from "../models/Query";
import axios, { ResponseType } from "axios";
import { Dispatch, SetStateAction } from "react";
import { Http_method } from "../hooks/useFetchDataOnMount";

export interface Params<T> {
  setter: Dispatch<SetStateAction<Query<T>>>;
  url: string;
  method: Http_method;
  body?: any;
  queryParams?: any;
  headers?: {[key: string]: any};
  responseType?: ResponseType;
  mapper?: (arg: any) => T;
}

export const is2XXStatusCode = (code?: number) => code?.toString().startsWith('2') ?? false
export const is4XXStatusCode = (code?: number) => code?.toString().startsWith('4') ?? false
export const is5XXStatusCode = (code?: number) => code?.toString().startsWith('5') ?? false

export const getAuthorizationHeaders = (token?: string) => (
  {
    'Authorization': `Bearer ${token}`
  }
)


export const query = async <T>({
  setter,
  url,
  method,
  body,
  queryParams,
  headers,
  responseType,
  mapper,
}: Params<T>) => {
  setter(setIsLoading(true));

  //@ts-ignore
  setter(setContent(undefined));
  try {
    const methodWithBody: Http_method[] = ["post", "put", "patch"];
    const config = { headers, params: queryParams, responseType: responseType };
    //@ts-ignore
    const res = methodWithBody.includes(method) ? await axios[method](url, body, config) : await axios[method](url, config);

    const data = mapper ? mapper(res.data) : res.data;
    setter(setContent(data));
    setter(setStatusCode(res.status))
    return data
  } catch (e) {
    if(axios.isAxiosError(e)) {
      setter(setError(e.message));
      setter(setStatusCode(e.response?.status))
    } else {
      setter(setError(e))
    }
  } finally {
    setter(setIsLoading(false));
  }
};

