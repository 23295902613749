import Box from "@material-ui/core/Box";
import React, {
  Children,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { Column, FlexBox, FlexBoxType, Row } from "../../components/FlexBox";
import { KyrButton } from "../../components/KyrButton";
import { KyrCancelTreeUpdatePopUp, KyrPublishTreePopUp } from "../../components/KyrPopUp";
import { StatusToUpdateContext, TreeContext, TreeListContext } from "../../contexts/organizeTreeBibleContexts";
import '../../globalStyle.css';
import { useHandleSearchChange } from "../../hooks/searchHooks";
import { ChildrenProps } from "../../types";
import { borderWith, box, height, marginBottom } from "./styles";
import { UDTank } from "./tank/UDTank";
import { Tree } from "./tree/Tree";
import { useHandleSaveTree } from "../../hooks/treeHooks";
import { VerticalAlignTop } from "@material-ui/icons";
import { FolderZip } from "mdi-material-ui";
import Tooltip from "@material-ui/core/Tooltip";
import { KyrSubTitle } from "../../components/KyrSubTitle";
import { KyrTitle } from "../../components/KyrTitle";
import { useFetchDataOnMount } from "../../hooks/useFetchDataOnMount";
import { useTranslation } from "react-i18next";
import { KyrHandleQuery } from "../../components/KyrHandleQuery";
import { useParams } from "react-router-dom";
import { useSetFaviconAndTabeLabel, useSetRootHeightTo100vh } from "../../hooks/UIhooks";


const TopLineContainer = (props: FlexBoxType & { direction: 'row' | 'column' }) => {
  return (
    <FlexBox
      {...props}
      style={{ flexDirection: props.direction }}
      height={height}
      paddingLeft={borderWith}
      marginBottom={marginBottom}
    />
  )
}

const TreeLabelAndButtonsLayout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <TopLineContainer
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      {childrenList[0]}
      <DoubleButtonsRow>
        {childrenList[1]}
        {childrenList[2]}
      </DoubleButtonsRow>
    </TopLineContainer>
  )
}

const DoubleButtonsRow = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)
  return (
    <Row colGap={7} >
      {childrenList[0]}
      {childrenList[1]}
    </Row>
  )
}

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  const udTankWidth = 45
  const treeBoxWidth = 99 - udTankWidth
  return (
    <>
      <Row
        id="organize-tree-global-container"
        grow={1}
        justifyContent="space-between"
        overflow="auto"
        padding="20px"
      >
        <Column width={`${udTankWidth}%`}>
          <TopLineContainer
            direction="column"
            justifyContent="space-between"
          >
            {childrenList[0]}
          </TopLineContainer>
          <Column style={box}>
            {childrenList[1]}
          </Column>
        </Column>
        <Column width={`${treeBoxWidth}%`}>
          {childrenList[2]}
          <Column grow={1} style={box}>
            {childrenList[3]}
          </Column>
        </Column>
      </Row>
    </>
  )
}

const UDTankLabel = ({ currentTree }: { currentTree?: string }) => {
  const {t} = useTranslation()
  return (
  <>
    <KyrTitle>{t('kyrielle.organizeTree.title', {cdfTitle: currentTree ? t(`kyrielle.organizeTree.cdfTitle.${currentTree}`) : '...'})}</KyrTitle>
    <KyrSubTitle>{t('kyrielle.organizeTree.elementsToInsert')}</KyrSubTitle>
  </>
)}

export const TreeLabelAndButtons = ({ setShow }: { setShow: Function }) => {
  const [tree] = useContext(TreeContext)
  const handleSearch = useHandleSearchChange()
  const handleSave = useHandleSaveTree()
  const {t} = useTranslation()
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)


  const handleClickOnSaveButton = async () => {
    await handleSave()
    setStatusToUpdate({})
    await handleSearch()
  }

  const handleClickOnCancelButton = () => {
    setShow(true)
  }

  return (
    <TreeLabelAndButtonsLayout>
      <KyrSubTitle>{t('kyrielle.organizeTree.cdf')}</KyrSubTitle>
      <KyrButton
        disabled={!tree.data?.isDirty}
        onClick={handleClickOnCancelButton}
        kyrType="secondary">
        {t('kyrielle.button.cancel')}
      </KyrButton>
      <KyrButton onClick={handleClickOnSaveButton} kyrType="primary">{t('kyrielle.button.save')}</KyrButton>
    </TreeLabelAndButtonsLayout>
  )
}

export const TreeActionButtons = ({ delivery, setShowPublishPopup }:
  { delivery: MutableRefObject<boolean>, setShowPublishPopup: Dispatch<SetStateAction<boolean>> }) => {
  const [tree] = useContext(TreeContext)

  const handleClickOnDeliveryButton = () => {
    delivery.current = true
    setShowPublishPopup(true)
  }

  const handleClickOnPublishButton = () => {
    delivery.current = false
    setShowPublishPopup(true)
  }
  return (
    <DoubleButtonsRow>
      <Tooltip title={tree.data?.isDirty ? "Modifications non enregistrées" : "Publier Cdf et livrables"}>
        <span>
          <KyrButton
            disabled={tree.data?.isDirty}
            kyrType="primary"
            endIcon={<FolderZip />}
            onClick={handleClickOnDeliveryButton}>
            Livrable
          </KyrButton>
        </span>
      </Tooltip>
     
    </DoubleButtonsRow>
  )
}

export const OrganizeTree = () => {

  useSetRootHeightTo100vh()

  const [show, setShow] = useState(false)
  const [tree] = useContext(TreeContext)

  const [treeList, setTreeList] = useFetchDataOnMount({
    url: '/api/kyrielle/tree',
    method: 'get',
    context: TreeListContext,
  })


  
  
  return (
    <KyrHandleQuery errorMessage="KYRIELLE_TREE_LIST_LOAD" query={treeList} setQuery={setTreeList}>
      <KyrCancelTreeUpdatePopUp showState={[show, setShow]} />
      <Layout>
        <UDTankLabel currentTree={tree.data?.type} />
        <UDTank />
        <TreeLabelAndButtons setShow={setShow} />
        <Tree />
      </Layout>
    </KyrHandleQuery>
  )
}
