import { Divider, IconButton, Menu, MenuItem, Tab, Tabs, Tooltip, createStyles, makeStyles } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import SettingsIcon from '@material-ui/icons/Settings'
import { TreeView } from "@material-ui/lab"
import React, { CSSProperties, Children, Dispatch, FC, MutableRefObject, SetStateAction, useContext, useRef, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Column, Row } from "../../../components/FlexBox"
import { KyrButton } from "../../../components/KyrButton"
import { SearchContext, StatusToUpdateContext, TreeContext, TreeListContext } from "../../../contexts/organizeTreeBibleContexts"
import { KyrHandleQuery } from "../../../components/KyrHandleQuery"
import { KyrChangeTreeTypePopUp, KyrPublishTreePopUp } from "../../../components/KyrPopUp"
import { useHandleSearchChange } from "../../../hooks/searchHooks"
import { useGetTree } from "../../../hooks/treeHooks"
import { useFetchDataOnMount } from "../../../hooks/useFetchDataOnMount"
import { TreeModel, TreeType } from "../../../models/TreeModels"
import { palette } from "../../../palette"
import { ChildrenProps, SetState } from "../../../types"
import { OrganizeNodeList } from "./OrganizeNodeList"
import { addNodeIdIntoDocumentUnit, getNodesId } from "./tree.utils"
import { useTranslation } from "react-i18next"
import { TREE_DASHED_BORDER } from "../../../elementsSize"


const useStylesMenu = makeStyles({
  indicator: {
    backgroundColor: palette.primary,
    height: '3px',
  },
});

const useTreeStyles = makeStyles({
  treeView: {
    '& ul': {
      borderLeftWidth: TREE_DASHED_BORDER,
    },
  },
});

interface MenuTabProps {
  value: TreeType
  setValue: (tab: TreeType) => void
  setExpanded: Dispatch<SetStateAction<string[]>>
}

const MenuTab = ({ value, setValue, setExpanded }: MenuTabProps) => {
  const classes = useStylesMenu();
  const handleGetTree = useGetTree()
  const handleSearchChange = useHandleSearchChange()
  const [search] = useContext(SearchContext)
  const [tree] = useContext(TreeContext)
  const [treeList] = useContext(TreeListContext)
  const { t } = useTranslation()
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)

  const handleMenuChange = (type: TreeType) => {
    setValue(type)
    setStatusToUpdate({})

    const treeId = treeList.data?.find(t => t.type === type)?.id
    if (treeId) handleGetTree(treeId.toString())
    if (search.type && treeId) {
      handleSearchChange({ ...search, treeId })
    }
    setExpanded([])
  }

  const changeRef = useRef<TreeType | null>(null)
  const [show, setShow] = useState(false)

  const handleConfirm = () => {
    if (changeRef.current === null) {
      throw Error('changeRef.current should not be null at this step')
    }

    handleMenuChange(changeRef.current)

  }

  return (
    <>
      <KyrChangeTreeTypePopUp
        showState={[show, setShow]}
        handleConfirm={handleConfirm}
      />
        <Tabs
          style={{ flexGrow: 1 }}
          value={value}
          onChange={async (_, type) => {
            if (tree.data?.isDirty) {
              changeRef.current = type
              setShow(true)
              return
            }

            handleMenuChange(type)
          }}
          classes={{ indicator: classes.indicator }}
          centered
          variant="scrollable"
          scrollButtons="auto"

        >
          {Object.values(TreeType).map((v, i) => (
            <Tab
              label={t(`kyrielle.organizeTree.tab.${v}`)}
              value={v}
              style={{
                color: value === v ? palette.primary : 'black', fontSize: 15
              }}
              key={i}
            />
          ))}
        </Tabs>
    </>
  );
};

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <Column grow={1} style={{ overflow: 'auto' }}>
      <Column>
        <Row id="tree-nav-bar">
          <div style={{ visibility: 'hidden' }}>{childrenList[1]}</div> {/* Pour garder le menu centré */}
          {childrenList[0]}
          {childrenList[1]}
        </Row>
        <Divider style={{ marginTop: 15, marginBottom: 10, backgroundColor: palette.unattachedStatusBorderCircle }} />
        <Row justifyContent="flex-end" id="open-close" >
          {childrenList[2]}
          {childrenList[3]}
        </Row>
      </Column>
      <Column grow={1} id="tree-view" style={{ overflow: 'auto' }}>
        {childrenList[4]}
      </Column>
    </Column>
  )
}

interface ContentType {
  expandedState: [string[], SetState<string[]>]
  tab: TreeType
}

type ItemType = { label: string, tooltipText?: string, handlerOrLink: string | (() => void), disabled?: boolean }
type ItemButtonProps = FC<{ item: Omit<ItemType, 'tooltipText'> } & { tab: TreeType }>

const ItemButton: ItemButtonProps = ({ item: { handlerOrLink, label, disabled }, tab }) => {
  const buttonMenuItemStyle: CSSProperties = {
    justifyContent: 'normal',
    padding: '0',
    width: '100%'
  }

  const linkStyle: CSSProperties = {
    color: palette.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none'
  }

  return (
    <KyrButton
      disabled={disabled}
      kyrType="text"
      style={buttonMenuItemStyle}
      onClick={typeof handlerOrLink === 'function' ? handlerOrLink : undefined}
    >
      {typeof handlerOrLink === 'string' ? (
        <Link
          style={linkStyle}
          to={handlerOrLink}
        >
          {label}
        </Link>
      ) : (
        label
      )}
    </KyrButton>
  )
}

const Content: FC<ContentType> = ({ expandedState: [expanded, setExpanded], tab }) => {
  const [treeList] = useContext(TreeListContext)

  const treeId = treeList.data?.find(t => t.type === tab)?.id

  const [treeState, setTreeState] = useFetchDataOnMount<TreeModel>({
    url: `/api/kyrielle/tree/${treeId}`,
    method: 'get',
    context: TreeContext,
    mapper: addNodeIdIntoDocumentUnit,
  })

  return (
    <KyrHandleQuery errorMessage="KYRIELLE_TREE_LOAD" query={treeState} setQuery={setTreeState}>
      <TreeView
        className={useTreeStyles().treeView}
        expanded={expanded}
        disableSelection
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<KeyboardArrowRightIcon />}
        onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
      >
        <OrganizeNodeList
          colorful={tab === TreeType.ACTES_CLAUSES}
          nodes={treeState?.data?.nodes ?? []}
        />
      </TreeView>
    </KyrHandleQuery>
  )
}
type AdminMenuType = FC<{ tab: TreeType, delivery: MutableRefObject<boolean>, setShowPublishPopup: SetState<boolean> }>
const AdminMenu: AdminMenuType = ({ tab, delivery, setShowPublishPopup }) => {
  const [tree] = useContext(TreeContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleClickOnDeliveryButton = () => {
    delivery.current = true
    setShowPublishPopup(true)
  }

  const handleClickOnPublishButton = () => {
    delivery.current = false
    setShowPublishPopup(true)
  }

  const [treeList] = useContext(TreeListContext)
  const treeId = treeList.data?.find(t => t.type === tab)?.id

  const items: ItemType[] = [
    {
      label: 'Administrer le chemin de fer',
      handlerOrLink: `/kyrielle/administrateTree/${treeId}`
    },
    {
      label: 'Livrable',
      tooltipText: tree.data?.isDirty ? "Modifications non enregistrées" : "Publier Cdf et livrables",
      handlerOrLink: handleClickOnDeliveryButton,
      disabled: tree.data?.isDirty 
    },
    {
      label: 'Cdf',
      tooltipText: tree.data?.isDirty ? "Modifications non enregistrées" : "Publier Cdf",
      handlerOrLink: handleClickOnPublishButton,
      disabled: tree.data?.isDirty 
    }
  ]

  return (
    <div>
      <IconButton onClick={handleClick}>
        <SettingsIcon style={{ fontSize: 25, color: palette.primary }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {
          items.map(({ handlerOrLink, label, tooltipText, disabled }) => (
            <Tooltip placement="bottom-start" title={tooltipText ?? ''}>
              <MenuItem onClick={!disabled ? handleClose : undefined}>
                <ItemButton item={{ handlerOrLink, label, disabled }} tab={tab} />
              </MenuItem>
            </Tooltip>
          ))
        }
      </Menu>
    </div>
  );
}


export const Tree = () => {
  const [expanded, setExpanded] = useState<string[]>([])
  const [treeList] = useContext(TreeListContext)
  const { id } = useParams<{ id?: string }>();

  // Soit on trouve un type qui a pour l'identifiant "id" dans l'url
  // Soit on renvoie par défaut Actes et clauses
  const tab = treeList.data?.find(t => t.id.toString() === id)?.type ?? TreeType.ACTES_CLAUSES;

  const history = useHistory();


  const [tree] = useContext(TreeContext)
  const treeId = id || treeList.data?.find(t => t.type === tab)?.id

  const changeTab = (tab: TreeType) => {
    const foundTree = treeList.data?.find(t => t.type === tab);
    history.push(`/kyrielle/organizeTree/${foundTree?.id}`)
  }

  const delivery = useRef(false)
  const [showPublishPopup, setShowPublishPopup] = useState(false)

  const handleOpen = () => {
    setExpanded(tree.data?.nodes.flatMap(getNodesId) ?? [''])
  }

  const handleClose = () => {
    setExpanded([])
  }

  const Minus = () => (
    <span style={{ borderTop: 'solid 1px', borderBottom: 'solid 1px', width: 10, marginRight: 4 }} />
  )

  return (
    <>
      <KyrPublishTreePopUp showState={[showPublishPopup, setShowPublishPopup]} delivery={delivery.current} />
      <Layout>
        <MenuTab value={tab} setValue={changeTab} setExpanded={setExpanded} />
        <AdminMenu tab={tab} delivery={delivery} setShowPublishPopup={setShowPublishPopup} />
        <KyrButton style={{ fontWeight: 'unset' }} kyrType="text" onClick={handleOpen}>+ déplier</KyrButton>
        <KyrButton style={{ fontWeight: 'unset' }} kyrType="text" onClick={handleClose}>
          <Minus /> replier
        </KyrButton>
        <Content expandedState={[expanded, setExpanded]} tab={tab} />
      </Layout>
    </>
  )
}