import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import {
  DocumentUnitContext,
  SearchContext,
} from "../contexts/organizeTreeBibleContexts";
import { Query } from "../models/Query";
import { DEFAULT_PAGE, DEFAULT_SIZE, Pageable } from "../models/Pageable";
import { Search } from "../models/Search";
import { PublicationPlan } from "../models/publicationPlan";
import { PeriodicalId } from "../pages/organizeTree/tank/LexPressAdvancedFilters";
import { query } from "../utils/query";
import { EntityProposal } from "../../types/quick-search";

/**
 * A custom React Hook that handles search changes.
 *
 * @template T The type of the Pageable object.
 * @param {boolean} debounce If true, the search will be debounced by 500ms. Default is false.
 * @returns {Function} A function that takes an updated Search object and an optional Pageable object.
 * The function will update the search state, and then fetch data from the server based on the updated search.
 * If debounce is true, the data fetching will be debounced by 500ms.
 */
export function useHandleSearchChange<T>() {
  // Get the auth token from the Redux state
  const token = useSelector(({ auth }: State) => auth.token);

  // Set up the headers for the fetch request
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Get the setSearchState function from the SearchContext
  const [searchState, setSearchState] = useContext(SearchContext);

  // Get the setDocumentsUnitState function from the DocumentUnitContext
  const [, setDocumentsUnitState] = useContext(DocumentUnitContext);

  // Create a ref to store the timeout ID for debouncing
  const timeoutIdRef = useRef<number | null>(null);

  // Return a function that takes an updated Search object and an optional Pageable object
  // updatedSearch -> SC (SearchCriteria) updatedPageable: D (Data to update)
  // Si pas pageable faire une seule page "infini"
  return async (
    updatedSearchOrDefault?: Search,
    updatePageable?: Pageable<T>,
    debounce: boolean = false
  ) => {
    // Update the search state
    const updatedSearch = updatedSearchOrDefault ?? searchState;
    setSearchState(updatedSearch);

    // Get the page number and size from the Pageable object, or use defaults if not provided

    const page = updatePageable?.number ?? DEFAULT_PAGE;
    const size = updatePageable?.size ?? DEFAULT_SIZE;

    // Define the query to fetch data from the server
    const callback = async () =>
      query({
        url: "/api/kyrielle/document/search",
        setter: setDocumentsUnitState,
        method: "post",
        body: updatedSearch,
        queryParams: { page, size },
        headers,
      });

    // If debounce is true, debounce the data fetching by 500ms
    if (debounce) {
      if (timeoutIdRef.current !== null)
        window.clearTimeout(timeoutIdRef.current!!);
      timeoutIdRef.current = window.setTimeout(callback, 500);
    } else {
      // If debounce is false, fetch the data immediately
      callback();
    }
  };
}

type PlanNodeBody = {
  page: number;
  size: number;
  query: string;
  includePeriodical?: PeriodicalId;
  excludePeriodical?: PeriodicalId;
};
export function useHandlePlanNodeChange(
  setter: Dispatch<SetStateAction<Query<Pageable<PublicationPlan>>>>
) {
  const token = useSelector(({ auth }: State) => auth.token);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const timeoutIdRef = useRef<number | null>(null);

  return async (body: PlanNodeBody, debounce: boolean = false) => {
    const callback = async () =>
      query({
        url: "/api/publication_plan/search",
        setter,
        method: "post",
        body,
        headers,
      });

    if (debounce) {
      if (timeoutIdRef.current !== null)
        window.clearTimeout(timeoutIdRef.current!!);
      timeoutIdRef.current = window.setTimeout(callback, 500);
    } else {
      callback();
    }
  };
}

type PublicationSearchBody = { query: string }
export function useHandlePublicationChange(
  setter: Dispatch<SetStateAction<Query<EntityProposal[]>>>
) {
  const token = useSelector(({ auth }: State) => auth.token);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const timeoutIdRef = useRef<number | null>(null);

  return async (body: PublicationSearchBody, debounce: boolean = false) => {
    const callback = async () =>
      query({
        url: "/api/search/autocomplete/publication",
        setter,
        method: "post",
        body,
        headers,
      });

    if (debounce) {
      if (timeoutIdRef.current !== null)
        window.clearTimeout(timeoutIdRef.current!!);
      timeoutIdRef.current = window.setTimeout(callback, 500);
    } else {
      callback();
    }
  };
}


export function useThematicsChange(
  setter: Dispatch<SetStateAction<Query<string[]>>>
) {
  const token = useSelector(({ auth }: State) => auth.token);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const timeoutIdRef = useRef<number | null>(null);

  return async (search: string, debounce: boolean = false) => {
    const callback = async () =>
      query({
        url: "/api/kyrielle/oxygen/thematics",
        setter,
        method: "post",
        body: search,
        headers,
      });

    if (debounce) {
      if (timeoutIdRef.current !== null)
        window.clearTimeout(timeoutIdRef.current!!);
      timeoutIdRef.current = window.setTimeout(callback, 500);
    } else {
      callback();
    }
  };
}

export function useTypeActeChange(
  setter: Dispatch<SetStateAction<Query<string[]>>>
) {
  const token = useSelector(({ auth }: State) => auth.token);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const timeoutIdRef = useRef<number | null>(null);

  return async (search: string, debounce: boolean = false) => {
    const callback = async () =>
      query({
        url: "/api/kyrielle/oxygen/type-acte",
        setter,
        method: "post",
        body: search,
        headers,
      });

    if (debounce) {
      if (timeoutIdRef.current !== null)
        window.clearTimeout(timeoutIdRef.current!!);
      timeoutIdRef.current = window.setTimeout(callback, 500);
    } else {
      callback();
    }
  };
}
